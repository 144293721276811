div.basic-modal {
  header.modal-card-head {
    height: 50px;
  }

  button.primary-action {
    position: absolute;
    right: 20px;
  }
}
