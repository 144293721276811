nav.breadcrumb{
  letter-spacing: 0.5px;
  
  li {
    margin-right: 10px;
  }

  li::before{
    margin-right: 10px;
  }

  .breadcrumb.has-succeeds-separator li + li::before {
    content: '>';
  }

  .breadcrumb li:first-child a {
      padding: 0;
  }
}
