.section#media{
  .x-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
  .lock-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
  }
  .image-info {
    position: absolute;
    bottom: 0;
    text-align: left;
  }
  .no-wrap{
    white-space: nowrap;
  }
}
