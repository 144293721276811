.paginator-container {
  padding: 0;

  margin-left: 2px;
  margin-right: 2px;

  .pagination-next,
  .pagination-previous {
    color: #FFF;
    border: none;
    box-shadow: none !important;
  }


  .pagination-link {
    color: #333;

    &.is-current {
      color: #FFF;
      background-color: #3298dc;
    }
  }
}
