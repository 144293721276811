.preview-image {
  max-height: 160px;
  float: left;
  margin-right: 5px;
}

.break-word {
  word-break: break-word;
}

.blog-list-box {
  max-height: 18.5rem;
  overflow: hidden;
}
