$mysizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$mysizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

@function mysizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $mysizeUnit);
}

@each $mysize in $mysizes {
    $mysizeKey: nth($mysize, 1);
    $mysizeValue: nth($mysize, 2);
    .#{$marginKey}#{$separator}#{$mysizeKey} {
        margin: mysizeValue($mysizeKey, $mysizeValue) !important;
    }
    .#{$paddingKey}#{$separator}#{$mysizeKey} {
        padding: mysizeValue($mysizeKey, $mysizeValue) !important;
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$mysizeKey} {
            margin-#{$posValue}: mysizeValue($mysizeKey, $mysizeValue) !important;
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$mysizeKey} {
            padding-#{$posValue}: mysizeValue($mysizeKey, $mysizeValue) !important;
        }
    }
}
