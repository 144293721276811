@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colorsbrown: #757763;
$sidebar-link: #fff;
$light-yellow: #fffec8;
$beige-lighter: #eff0eb;


// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$info: #427116;
$primary: #ff995c;
$warning: #ffc900;
$danger: #ff5241;
$text: #4d2f02;
$link: $text;
$input-active-border-color: $beige-lighter;
$input-focus-border-color: $beige-lighter;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$menu-label-color: $sidebar-link;
$menu-item-color: $sidebar-link;
$menu-item-hover-color: $light-yellow;
$menu-item-hover-background-color: rgba(255,255,255,0.2);
$menu-item-active-color: $light-yellow;
$menu-item-active-background-color: rgba(255,255,255,0.2);
$link-active: $primary;
$link-hover: $primary;

@import './spacing.scss';

html, body, #root, .main, .section, .is-fullheight {
  height: 100%;
  font-size: 14px; }

main.main.with-padding {
  padding-left: 250px; }

.transition-all {
  transition: 250ms all; }

.has-text-light-yellow {
  color: $light-yellow; }

.button.is-secondary {
  border: 1px solid $primary;
  color: $primary; }

.section {
  padding-top: 4rem; }

.sidebar-toggler {
  position: fixed;
  left: 15px;
  top: 15px;
  z-index: 5; }

.no-scroll {
  overflow: hidden; }

h1 {
  font-size: 2rem; }

h2 {
  font-size: 1.75rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.25rem; }

h5 {
  font-size: 1.12rem; }

h6 {
  font-size: 1rem; }

.pointer {
  cursor: pointer; }
