.tooltip{
  position: absolute;
  pointer-events: none;
  visibility: hidden;

  &.is-active {
    visibility: visible;
    pointer-events: inherit;
  }
}
